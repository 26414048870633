import React, { useState } from 'react';
import Layout from '../../shared/Layout';
import Table from './design/Table';
import { Modal, Input } from 'antd';
import Popup from './design/Popup';

const Product = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [updateItem, setUpdateItem] = useState(null);
  const [current, setCurrent] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  const showModal = () => {
    setUpdateItem(null);
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const updateHandler = (value) => {
    setUpdateItem(value);
    setModalVisible(true);
  };

  // Handler for search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <Layout current="Product">
      <Modal
        title="Add Product"
        visible={modalVisible}
        onCancel={handleCancel}
        width={1000}
        footer={false}
      >
        <Popup updateItem={updateItem} current={current} onClose={handleCancel} />
      </Modal>
      <div className='bg-white p-8 h-full'>
        <div className='text-3xl font-semibold'>Product</div>
        <div className='flex justify-between mb-4 mt-4'>
          <Input
            placeholder="Search products"
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-64"
          />
          <div className='flex ml-auto gap-1 items-center cursor-pointer bg-[#2B2D42] px-4 py-2 text-white font-medium rounded-xl' onClick={showModal}>
            <img className='h-5 w-5 ' src='./img/plus.svg' alt='' />
            <div>Add Product</div>
          </div>
        </div>
        <Table
          current={current}
          setCurrent={setCurrent}
          updateHandler={updateHandler}
          searchTerm={searchTerm}
        />
      </div>
    </Layout>
  );
};

export default Product;