import React, { useEffect, useState } from "react";
import API from "../../../utils/base.service";

const initialState = {
  name: "",
  key: "",
  image: "",
  preview: ""
};

const ProductPopup = ({ updateItem, onClose }) => {
  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    if (updateItem) {
      setFormData(updateItem);
    } else {
      setFormData(initialState);
    }
  }, [updateItem]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const apiBody = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      apiBody.append(key, value);
    });

    API.post("/category/new", apiBody)
      .then(() => {
        onClose()
        setFormData({
          name: "",
          key: "",
          image: "",
          preview: ""
        })
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      name: value,
      key: value.toLowerCase().replace(/\s+/g, "_")
    }));
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        image: file,
        preview: reader.result,
      }));
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-8  flex flex-col items-center justify-center">
        <img className="h-auto w-48 rounded-2xl" src={updateItem?.image || formData.preview} alt="" />
        <input type="file" accept="image/*" onChange={handleImageUpload} />
        <div className="font-semibold text-center">Add Category Image</div>
      </div>

      <div className="flex flex-col w-full mt-8">
        <div className="border-2 w-full border-grey p-2 rounded-lg text-lg">
          <input
            className="border-none outline-none w-full"
            placeholder="Name"
            type="text"
            id="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex items-center justify-center">
        <button
          type="submit"
          className=" w-fit px-8 py-2 text-xl font-bold  mt-10 rounded-lg text-white bg-black"
        >
          Add Category
        </button>
      </div>
    </form>
  );
};

export default ProductPopup;
