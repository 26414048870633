/* eslint-disable react-hooks/exhaustive-deps */
"use Client";

import React, { createContext, useContext, useState } from "react";

const UserContext = createContext(undefined);

export function UsersProvider({ children }) {
  const [users, setUsers] = useState([]);

  return (
    <UserContext.Provider value={{ users, setUsers }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUsers() {
  const context = useContext(UserContext);

  if (context === "undefined") {
    throw new Error("Order must be used within a Order Provider");
  }

  return context;
}
