import React from "react";
import Customers from "../../components/modules/Customers";

const CustomersPage = () => {
  return (
    <Customers />
  );
};

export default CustomersPage;
