import { Steps } from "antd";
import React from "react";

const ProductCard = ({ data, total, id, returnOrderHandler }) => {
    return (
        <div>
            <div className="my-4 mx-auto flex justify-between gap-4">
                <div className="w-40">
                    <Steps
                        progressDot
                        current={data?.orderStatus === "Processing" ? 1 : 0}
                        items={[
                            {
                                title: "Order Placed",
                            },
                            {
                                title: "In Progress",
                            },
                            {
                                title: "Shipped",
                            },
                            {
                                title: "Delivered",
                            },
                        ]}
                    />
                </div>
                <button
                    className="bg-black p-2 w-32 h-fit rounded-lg text-white"
                    onClick={() => returnOrderHandler(id)}
                >
                    Add Tracking
                </button>
            </div>
            <div className="flex items-center justify-between my-10">
                <div className="flex items-center gap-6">
                    {/* {data?.images[0] && (
                        <div className="w-16 h-16 bg-white flex justify-center">
                            <img
                                src={data?.images[0]}
                                alt={data?.name}
                                height={30}
                                width={30}
                            />
                        </div>
                    )} */}
                    <div className="text-[#3C4242] font-extrabold">
                        <div className="text-[#3C4242]">{data?.name}</div>
                    </div>
                </div>
                <div className="flex items-center gap-20 text-[#807D7E] font-extrabold">
                    <div className="text-[#3C4242]">
                        QTY: <span className="text-[#807D7E]">{data?.quantity}</span>{" "}
                    </div>
                    <div>${total}</div>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;
