/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import API from "../../../utils/base.service";
import { useUsers } from "../../../context/UsersProvider";

const App = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const {users, setUsers} = useUsers();
  const hasSelected = selectedRowKeys.length > 0;
  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Subscription",
      dataIndex: "subsribeFlag",
      render: (val) => (
        <div>{`${val}`}</div>
      )
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <div className='flex items-center gap-2'>
          {/* <img className="cursor-pointer" src='/img/pen.svg' alt='' onClick={() => updateHandler(record)} /> */}
          <img className="cursor-pointer" src='/img/trash.svg' alt='' onClick={() => deleteHandler(record)} />
        </div>
      )
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    type: "checkbox",
  };

  const deleteHandler = (value) => {
    API.delete(`/admin/user/${value?._id}`).then((res) => {
      API.get("/admin/users")
        .then((resp) => {
          setUsers(resp.data.users);
        })
        .catch((error) => {
          console.log(error);
        });
    }).catch((err) => console.log(err))
  }

  useEffect(() => {
    API.get("/admin/users")
      .then((resp) => {
        setUsers(resp.data.users);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <div
        style={{
          marginBottom: 16,
        }}
      >
        <span
          style={{
            marginLeft: 8,
          }}
        >
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </div>
      <Table rowSelection={rowSelection} columns={columns} dataSource={users} />
    </div>
  );
};

export default App;
