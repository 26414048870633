import React from "react";
import Product from "../../components/modules/Product";

const ProductPage = () => {
  return (
    <div>
      <Product />
    </div>
  );
};

export default ProductPage;
