import React, { useEffect, useState } from "react";
import { Table } from "antd";
import API from "../../../utils/base.service";
import { useNavigate } from "react-router-dom";
import { useOrders } from "../../../context/OrderProvider";

const TableUi = ({ items }) => {
  const navigate = useNavigate()
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { orders, setOrders } = useOrders();
  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
    },
    {
      title: "Order Status",
      dataIndex: "orderStatus",
    },
    {
      title: "Order Items",
      dataIndex: "orderItems",
      render: (_, record) => (<div>{record?.orderIDs?.length}</div>)
    },
    {
      title: "Total Price",
      dataIndex: "totalPrice",
      render: (val) => (<div>$ {val}</div>)
    }
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    type: "checkbox",
  };

  const handleRowClick = (record) => {
    navigate(`/order/${record?._id}`);
  };

  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    API.get("/admin/orders")
      .then((resp) => {
        setOrders(resp.data.orders);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div
        style={{
          marginBottom: 16,
        }}
      >
        <span
          style={{
            marginLeft: 8,
          }}
        >
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={items ? orders : orders?.slice(0, items)}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
      />
    </div>
  );
};

export default TableUi;
