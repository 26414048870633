import React, { useEffect, useState } from "react";
import { Table } from "antd";
import API from "../../../utils/base.service";

const TableUi = ({ updateHandler }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [value, SetValue] = useState([]);
  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      render: (image) => (
        <div>
          <img className="h-10 w-10" src={image} alt="" />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Key",
      dataIndex: "key",
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <div className='flex items-center gap-2'>
          <img className="cursor-pointer" src='/img/pen.svg' alt='' onClick={() => updateHandler(record)} />
          <img className="cursor-pointer" src='/img/trash.svg' alt='' onClick={() => deleteHandler(record)} />
        </div>
      )
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    type: "checkbox",
  };

  const hasSelected = selectedRowKeys.length > 0;

  const deleteHandler = (value) => {
    API.delete(`/category/${value?._id}`).then((res) => {
      API.get("/category")
        .then((resp) => {
          SetValue(resp.data.category);
          console.log(resp)
        })
        .catch((error) => {
          console.log(error);
        });
    }).catch((err) => console.log(err))
  }

  useEffect(() => {
    API.get("/category")
      .then((resp) => {
        SetValue(resp.data.category);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <div
        style={{
          marginBottom: 16,
        }}
      >
        <span
          style={{
            marginLeft: 8,
          }}
        >
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </div>
      <Table rowSelection={rowSelection} columns={columns} dataSource={value} />
    </div>
  );
};

export default TableUi;
