import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TbCategoryPlus } from "react-icons/tb";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

const sidebarOptions = [
  { path: "/", text: "Dashboard", icon: "./img/grid alt.svg" },
  { path: "/product", text: "Product", icon: "./img/shoping bag.svg" },
  { path: "/category", text: "Category", icon: TbCategoryPlus, isIcon: true },
  { path: "/order", text: "Order", icon: "./img/cart.svg", badge: "2" },
  { path: "/customer", text: "Customers", icon: "./img/users.svg" },
  { path: "/tickets", text: "Tickets", icon: "./img/store.svg" }
];

const Sidebar = ({ current }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {/* Mobile Menu Button */}
      <button 
        className="xl:hidden p-4 fixed top-4 left-4 z-50 bg-[#EF233C] text-white rounded-md"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? <AiOutlineClose size={24} /> : <AiOutlineMenu size={24} />}
      </button>

      <div className={`fixed top-0 left-0 h-full w-48 lg:w-48 bg-white border-r-2 transition-transform transform ${isOpen ? "translate-x-0" : "-translate-x-full"} xl:translate-x-0 z-40`}>
        <Link to="/" className="p-4 flex justify-center mt-20 xl:mt-0">
          <img className="h-6 w-auto " src="./img/Pure vibes..svg" alt="Logo" />
        </Link>
        <div className="mt-10 px-4">
          {sidebarOptions?.map((option, index) => (
            <Link
              key={index}
              to={option.path}
              className={`flex gap-3 items-center ${current === option.text ? "bg-[#EF233C] p-4 rounded-2xl text-white" : "p-4"}`}
              onClick={() => setIsOpen(false)}
            >
              <div className="flex gap-3 items-center">
                {option.isIcon ? (
                  <option.icon size={24} className={current === option.text ? "text-white" : "text-[#667085]"} />
                ) : (
                  <img src={option.icon} alt={option.text} className="h-6 w-6" />
                )}
                <div>{option.text}</div>
              </div>
              {/* {option.badge && (
                <div className="rounded-full h-5 w-5 flex justify-center items-center text-xs text-white bg-[#EF233C]">
                  {option.badge}
                </div>
              )} */}
            </Link>
          ))}
        </div>
      </div>

      {/* Overlay for mobile */}
      {isOpen && <div className="fixed inset-0 bg-black opacity-50 z-30 xl:hidden" onClick={() => setIsOpen(false)} />}
    </>
  );
};

export default Sidebar;
