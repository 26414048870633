import React, { useState } from "react";
import Layout from "../../shared/Layout";
import Table from "./design/Table";
import { Modal } from "antd";
import Popup from "./design/Popup";

const Tickets = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [updateItem, setUpdateItem] = useState(null);

  const handleCancel = () => {
    setModalVisible(false);
  };

  const updateHandler = (value) => {
    setUpdateItem(value)
    setModalVisible(true)
  }

  return (
    <Layout current="Tickets">
      <Modal
        visible={modalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Popup updateItem={updateItem} onClose={handleCancel} />
      </Modal>
      <div className="bg-white p-8 h-full">
        <div className="text-3xl font-semibold">Tickets</div>
        <Table updateHandler={updateHandler} />
      </div>
    </Layout>
  );
};

export default Tickets;
