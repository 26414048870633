import React from "react";
import Dashboard from "../../components/modules/Dashboard";

const DashboardPage = () => {
  return (
    <Dashboard />
  );
};

export default DashboardPage;
