import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import DashboardPage from './pages/dashboard'
import CustomersPage from './pages/customers'
import OrdersPage from './pages/orders'
import TicketsPage from "./pages/tickets"
import ProductPage from './pages/product'
import CategoryPage from './pages/category'
import LogInPage from './pages/login'
import OrderDetails from './components/modules/OrderDetails'

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LogInPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/customer" element={<CustomersPage />} />
        <Route path="/category" element={<CategoryPage />} />
        <Route path="/order" element={<OrdersPage />} />
        <Route path="/tickets" element={<TicketsPage />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/order/:id" element={<OrderDetails />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App