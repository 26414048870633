/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Table as AntTable } from 'antd';
import API from '../../../utils/base.service';
import { useProduct } from '../../../context/ProductProvider';
import { FaExternalLinkAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Table = ({ updateHandler, current, setCurrent, searchTerm }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { products, setProducts } = useProduct();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [total, setTotal] = useState(1);

  const columns = [
    {
      title: 'Images',
      dataIndex: 'images',
      render: (images) => (
        <div>
          <img className="h-10 w-10" src={images[0]} alt="" />
        </div>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Category',
      dataIndex: 'category',
    },
    {
      title: 'Price',
      dataIndex: 'price',
    },
    {
      title: 'Ratings',
      dataIndex: 'ratings',
    },
    {
      title: 'Stock',
      dataIndex: 'stock',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      render: (_, record) => (
        <div className='flex items-center gap-2'>
          <img className="cursor-pointer" src='/img/pen.svg' alt='' onClick={() => updateHandler(record)} />
          <img className="cursor-pointer" src='/img/trash.svg' alt='' onClick={() => deleteHandler(record)} />
          <Link to={record.productLink || ""} target='_blank'><FaExternalLinkAlt className="cursor-pointer" /></Link>
        </div>
      ),
    },
  ];

  const onChange = (page) => {
    setCurrent(page.current);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    type: 'checkbox',
  };

  const hasSelected = selectedRowKeys.length > 0;

  const deleteHandler = (value) => {
    API.delete(`/admin/product/${value?._id}`).then((res) => {
      API.get('/products')
        .then((resp) => {
          setProducts(resp.data.products);
        })
        .catch((error) => {
          console.log(error);
        });
    }).catch((err) => console.log(err));
  };

  // useEffect(() => {
  //   API.get(`/products?page=${current}`)
  //     .then((resp) => {
  //       setProducts(resp.data.products);
  //       setTotal(resp.data.productsCount);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, [current]);

  useEffect(() => {
    // Construct API endpoint with search query and pagination
    const fetchProducts = () => {
      let query = `/products?page=${current}`;
      
      if (searchTerm) {
        query += `&search=${searchTerm}`;
      }
  
      API.get(query)
        .then((resp) => {
          setProducts(resp.data.products);
          setTotal(resp.data.productsCount);
        })
        .catch((error) => {
          console.log(error);
        });
    };
  
    fetchProducts();
  }, [current, searchTerm]);

  useEffect(() => {
    if (searchTerm) {
      const filtered = products.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.category.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(products);
    }
  }, [products, searchTerm]);

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
        </span>
      </div>
      <AntTable
        rowSelection={rowSelection}
        columns={columns}
        dataSource={filteredProducts}
        pagination={{
          current,
          pageSize: 15,
          total,
          showSizeChanger: false,
        }}
        onChange={onChange}
      />
    </div>
  );
};

export default Table;
