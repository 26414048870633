import React, { useState } from "react";
import API from "../../utils/base.service";
import { useAuth } from "../../context/AuthProvider";
import AuthLayout from "../../shared/AuthLayout";

const LogIn = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const { setAuth } = useAuth()

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    API.post("/login", { email: formData.email, password: formData.password })
      .then((res) => {
        if (res) {
          localStorage.setItem("pure-vibe-admin-token", res?.data?.token)
          setAuth({ status: true, user: res?.data?.user })
        }
      })
      .catch((err) => console.log(err))
  };

  return (
    <AuthLayout>
      <div className="flex flex-col justify-center h-screen items-center">
        <div>
          <img className="h-12 w-auto" src="/img/Pure vibes..svg" alt="" />
        </div>
        <form onSubmit={handleSubmit} className="flex-col w-[30%] mt-14">
          <div>
            <div className="text-xl font-medium">Email:</div>
            <div className="w-full border-2 border-grey p-2 rounded-xl">
              <input
                className="outline-none border-none w-full"
                placeholder="email"
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange} />
            </div>
          </div>
          <div className="mt-3">
            <div className="text-xl font-medium">Password:</div>
            <div className="w-full border-2 border-grey p-2 rounded-xl flex">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="flex-1 border-none text-black outline-none" />
              <button
                type="button"
                onClick={handleTogglePasswordVisibility}
                className="text-lg cursor-pointer focus:outline-none"
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
          </div>
          <div className=" mt-10">
            <button
              type="submit"
              className="border-2 px-14 py-2 rounded-lg font-bold bg-black text-white text-lg"
            >
              Log In
            </button>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default LogIn;
