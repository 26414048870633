import React from 'react'
import Layout from '../../shared/Layout'
import Table from './design/Table'

const Customers = () => {
  return (
    <Layout current="Customers">
      <div className='bg-white p-8 h-full'>
        <div className='text-3xl font-semibold'>Users</div>
        
        <Table />
      </div>
    </Layout>
  )
}

export default Customers