import React, { useEffect, useState } from 'react'
import Layout from '../../shared/Layout'
import TableUi from '../Orders/design/Table';
import API from '../../utils/base.service';
import LineGraph from '../../shared/Linegraph';
import HalfDonutGraph from '../../shared/HalfDonutGraph';

const Dashboard = () => {
  const [data, setData] = useState([
    { title: 'Total Revenue', value: '$0' },
    { title: 'Total Sales', value: '0' },
    { title: 'Total Orders', value: '0' },
    { title: 'Total Tickets', value: '0' }
  ]);

  useEffect(() => {
    API.get("/admin/dashboard")
      .then((res) => {
        const newData = [
          { title: 'Total Revenue', value: '$' + res.data.totalRevenue },
          { title: 'Total Sales', value: res.data.totalSalesFinished },
          { title: 'Total Orders', value: res.data.totalPendingOrders },
          { title: 'Total Tickets', value: res.data.totalTickets }
        ];
        setData(newData);
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);

  return (
    <Layout current="Dashboard">
      <div className='bg-white p-8 h-full'>
        <div className='text-3xl font-semibold'>Dashboard</div>
        <div className='grid grid-cols-4 gap-4 mt-10'>
          {data?.map((item, index) => (
            <div key={index} className='w-full h-32 shadow-lg rounded-lg flex flex-col justify-between p-4'>
              <div className='text-xl '>{item.title}</div>
              <div className='flex'>
                <div className='text-4xl font-semibold'>{item.value}</div>
              </div>
            </div>
          ))}
        </div>
        <div className='flex gap-4 w-full'>
          <div className='flex-1'>
            <div className='text-3xl font-semibold py-10'>Orders</div>
            <LineGraph />
          </div>
          <div className='flex-1'>
            <div className='text-3xl font-semibold py-10'>Subscriptions</div>
            <HalfDonutGraph />
          </div>
        </div>
        <div className='text-3xl font-semibold py-10'>Latest Orders</div>
        <TableUi items={10} />
      </div>
    </Layout>
  )
}

export default Dashboard