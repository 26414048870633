import React, { useEffect, useState } from "react";
import API from "../../../utils/base.service";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useProduct } from "../../../context/ProductProvider";
import axios from "axios";

const initialState = {
  name: "",
  category: "",
  description: "",
  price: 0,
  seller: "",
  productLink: "",
  stock: 0,
};

const ProductPopup = ({ updateItem, onClose, current }) => {
  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState(initialState);
  const { setProducts } = useProduct();

  const fields = [
    {
      id: 'name',
      type: 'text',
      placeholder: 'Name',
      value: formData.name,
      col: "col-span-1",
      onChange: (e) => setFormData((prevFormData) => ({ ...prevFormData, name: e.target.value })),
    },
    {
      id: 'category',
      type: 'select',
      options: categories,
      value: formData.category,
      col: "col-span-1",
      onChange: (e) => setFormData((prevFormData) => ({ ...prevFormData, category: e.target.value })),
    },
    {
      id: 'price',
      type: 'number',
      placeholder: 'Price',
      value: formData.price,
      col: "col-span-1",
      onChange: (e) => setFormData((prevFormData) => ({ ...prevFormData, price: e.target.value })),
    },
    {
      id: 'stock',
      type: 'number',
      placeholder: 'Stock',
      value: formData.stock,
      col: "col-span-1",
      onChange: (e) => setFormData((prevFormData) => ({ ...prevFormData, stock: e.target.value })),
    },
    {
      id: 'seller',
      type: 'text',
      placeholder: 'Seller',
      value: formData.seller,
      col: "col-span-1",
      onChange: (e) => setFormData((prevFormData) => ({ ...prevFormData, seller: e.target.value })),
    },
    {
      id: 'productLink',
      type: 'text',
      placeholder: 'productLink',
      value: formData.productLink,
      col: "col-span-1",
      onChange: (e) => setFormData((prevFormData) => ({ ...prevFormData, productLink: e.target.value })),
    },
    {
      id: 'description',
      type: 'textarea',
      placeholder: 'Write the description',
      col: "col-span-2",
      value: formData.description,
      onChange: (value) => setFormData((prevFormData) => ({ ...prevFormData, description: value })),
      component: (
        <ReactQuill
          value={formData.description}
          onChange={(value) => setFormData((prevFormData) => ({ ...prevFormData, description: value }))}
          placeholder="Write the description"
        />
      ),
    },
  ];

  useEffect(() => {
    if (updateItem) {
      setFormData(updateItem);
    } else {
      setFormData(initialState);
    }
  }, [updateItem]);

  useEffect(() => {
    API.get("/category").then((res) => {
      setCategories(res?.data?.category)
    }).catch((err) => console.log(err))
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault();
    const apiBody = new FormData();

    Object.entries(formData).forEach(([key, value]) => {
      if (key !== "images") {
        apiBody.append(key, value);
      }
    });

    if (updateItem) {
      if (formData?.images[0]?.includes("http")) {
        axios.get(formData?.images[0], { responseType: 'blob' })
          .then(response => {
            const blob = response.data;
            const file = new File([blob], "image.jpg", { type: blob.type });
            apiBody.append("images", file);
            API.put(`/admin/product/${updateItem?._id}`, apiBody)
              .then(() => {
                onClose();
                setFormData(initialState);
                getProducts();
              })
              .catch((error) => {
                console.error("Error submitting form:", error);
              });
          })
          .catch(error => {
            console.error("Error fetching image:", error);
          });
      } else {
        apiBody.append("images", formData.images);
        API.put(`/admin/product/${updateItem?._id}`, apiBody)
          .then(() => {
            onClose();
            setFormData(initialState);
            getProducts();
          })
          .catch((error) => {
            console.error("Error submitting form:", error);
          });
      }
    } else {
      apiBody.append("images", formData.images);
      API.post("/admin/product/new", apiBody)
        .then(() => {
          onClose();
          setFormData(initialState);
          getProducts();
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
        });
    }
  };

  const getProducts = () => {
      API.get(`/products?page=${current}`)
      .then((resp) => {
        setProducts(resp.data.products);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleImageUpload = (event) => {
    const file = event?.target?.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        images: file,
        preview: reader?.result,
      }));
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-8 flex flex-col items-center justify-center">
        <img className="h-auto w-48 rounded-2xl" src={updateItem?.images[0] || formData.preview} alt="" />
        <input type="file" accept="image/*" onChange={handleImageUpload} />
        <div className="font-semibold text-center">Add Product Image</div>
      </div>
      <div className="grid grid-cols-2 gap-2">
        {fields.map((field, idx) => (
          <div key={idx} className={`mt-4 flex flex-col w-full ${field.col}`}>
            <div className="border-2 w-full border-grey p-2 rounded-lg text-lg">
              {field.type === 'select' ? (
                <select
                  className="border-none outline-none w-full"
                  id={field.id}
                  value={field.value}
                  onChange={handleChange}
                >
                  {field.options.map((option, idx) => (
                    <option key={idx} value={option.key}>{option.name}</option>
                  ))}
                </select>
              ) : field.type === 'textarea' ? (
                <>
                  {field.component}
                </>
              ) : (
                <input
                  className="border-none outline-none w-full"
                  placeholder={field.placeholder}
                  type={field.type}
                  id={field.id}
                  value={field.value}
                  onChange={handleChange}
                />
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="flex items-center justify-center">
        <button
          type="submit"
          className=" w-fit px-8 py-2 text-xl font-bold  mt-10 rounded-lg text-white bg-black"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default ProductPopup;
