/* eslint-disable react-hooks/exhaustive-deps */
"use Client";

import React, { createContext, useContext, useState } from "react";

const ProductContext = createContext(undefined);

export function ProductProvider({ children }) {
  const [products, setProducts] = useState([]);

  return (
    <ProductContext.Provider value={{ products, setProducts }}>
      {children}
    </ProductContext.Provider>
  );
}

export function useProduct() {
  const context = useContext(ProductContext);

  if (context === "undefined") {
    throw new Error("Product must be used within a Product Provider");
  }

  return context;
}
