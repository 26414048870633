import React from "react";
import LogIn from "../../components/modules/Login";

const LogInPage = () => {
  return (
    <LogIn />
  );
};

export default LogInPage;
