import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './components/context/AuthProvider';
import { ProductProvider } from './components/context/ProductProvider';
import { OrdersProvider } from './components/context/OrderProvider';
import { UsersProvider } from './components/context/UsersProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ProductProvider>
        <OrdersProvider>
          <UsersProvider>
            <App />
          </UsersProvider>
        </OrdersProvider>
      </ProductProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
