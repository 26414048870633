import React, { useEffect } from "react";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { useAuth } from "../../context/AuthProvider";
import { useNavigate } from "react-router-dom";

const Layout = ({ children, current }) => {
  const { auth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.status) {
      navigate("/");
    }
  }, [auth, navigate]);

  return (
    <div className="flex">
      <div className="w-20 xl:w-48">
        <Sidebar current={current} />
      </div>

      <div className="flex flex-1 flex-col min-h-screen">
        <div className="relative h-[70px] w-full">
          <Header />
        </div>

        <div className="h-full flex-1 bg-[#EDEFF1] p-4 md:p-8">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
