import React from "react";
import Orders from "../../components/modules/Orders";

const OrdersPage = () => {
  return (
    <Orders />
  );
};

export default OrdersPage;
