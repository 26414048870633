import React, { useState } from "react";
import Layout from "../../shared/Layout";
import Table from "./design/Table";
import { Modal } from "antd";
import Popup from "./design/Popup";

const Category = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const [updateItem, setUpdateItem] = useState(null);

  const showModal = () => {
    setUpdateItem(null)
    setModalVisible(true);
  };
  const handleCancel = () => {
    setModalVisible(false);
  };

  const updateHandler = (value) => {
    setUpdateItem(value)
    setModalVisible(true)
  }

  return (
    <Layout current="Category">
      <Modal
        visible={modalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Popup updateItem={updateItem} onClose={handleCancel} />
      </Modal>
      <div className="bg-white p-8 h-full">
        <div className="text-3xl font-semibold">Category</div>
        <div className="flex justify-between ">
          <div className='flex ml-auto gap-1 items-center bg-[#2B2D42] px-4 py-2 text-white font-medium rounded-xl' onClick={showModal}>
            <img className='h-5 w-5 ' src='./img/plus.svg' alt='' />
            <div>Add Category</div>
          </div>
        </div>
        <Table updateHandler={updateHandler} />
      </div>
    </Layout>
  );
};

export default Category;
