/* eslint-disable react-hooks/exhaustive-deps */
"use Client";

import React, { createContext, useContext, useState } from "react";

const OrderContext = createContext(undefined);

export function OrdersProvider({ children }) {
  const [orders, setOrders] = useState([]);

  return (
    <OrderContext.Provider value={{ orders, setOrders }}>
      {children}
    </OrderContext.Provider>
  );
}

export function useOrders() {
  const context = useContext(OrderContext);

  if (context === "undefined") {
    throw new Error("Order must be used within a Order Provider");
  }

  return context;
}
