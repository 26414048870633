import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts';
import API from '../../utils/base.service';

const HalfDonutGraph = () => {
    const [chartSeries, setChartSeries] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);

    useEffect(() => {
        API.get("/admin/get-subscribed-user")
            .then(response => {
                const { subscribedCount, nonSubscribedCount } = response.data;
                const total = subscribedCount + nonSubscribedCount;
                setTotalUsers(total);
                setChartSeries([subscribedCount, nonSubscribedCount]);
            })
            .catch(error => {
                console.error("Error fetching subscribed user count:", error);
            });
    }, []);

    const chartOptions = {
        chart: {
            type: 'pie',
        },
        labels: ['Subscribed', 'Non-Subscribed'],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom',
                }
            }
        }],
        plotOptions: {
            pie: {
                customScale: 0.8, // Adjust the size of the pie chart
                donut: {
                    size: '65%', // Adjust the size of the donut hole
                }
            }
        },
    }

    return (
        <div className='relative'>
            <div className='absolute text-5xl font-bold flex justify-center items-center left-[-65px] w-full h-full' >
                <h2>{totalUsers}</h2>
            </div>
            <Chart
                options={chartOptions}
                series={chartSeries}
                type="donut"
                height={300}
            />
        </div>
    )
}

export default HalfDonutGraph;
