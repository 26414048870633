/* eslint-disable react-hooks/exhaustive-deps */
"use State";
import { Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../../utils/base.service";
import OrderSlip from "./design/OrderSlip";
import Layout from "../../shared/Layout";
import ProductCard from "./design/ProductCard";

const OrderDetails = () => {
    const { id } = useParams();
    const [isModalVisible, setModalVisible] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [orderDetails, setOrderDetails] = useState();
    const [trackingId, setTrackingId] = useState();
    const [orderId, setOrderId] = useState();

    useEffect(() => {
        API.get(`/order/${id}`)
            .then((res) => { console.log(res); setOrderDetails(res?.data?.order); setLoading(false) })
            .catch((err) => console.log(err));
    }, []);

    const returnOrderHandler = (id) => {
        setOrderId(id)
        setModalVisible(true);
    };

    const handleOk = () => {
        setModalVisible(false);
    };

    const handleCancel = () => {
        setModalVisible(false);
    };

    const submitHandler = (e) => {
        e.preventDefault();
        API.put("/order/traking", { id: orderId, trackingId })
            .then((res) => { handleCancel() })
            .catch((err) => console.log(err))
    }

    return (
        <Layout current="Order">
            <Modal
                title="Add Tracking"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <form onSubmit={submitHandler} className="p-4">
                    <input className="border-2 rounded-lg border-grey outline-none p-3 w-full" type="text" placeholder="Add Traking Id" value={trackingId} onChange={(e) => setTrackingId(e.target.value)} />
                    <input type="submit" className="bg-rose-600 rounded-lg mt-4 w-full text-white p-2" />
                </form>
            </Modal>
            {isLoading ?
                <Spin size="large">
                    <div style={{ minHeight: "200px", position: "relative" }}></div>
                </Spin>
                :
                <div className="bg-[#F9F9FC]">
                    <div className="container">
                        <div className="p-6">
                            <div className="flex items-center justify-between">
                                <div className="text-xl font-semibold mb-10">Order Details</div>
                            </div>

                            <div className="bg-white p-6">
                                <OrderSlip data={orderDetails} />
                                <div className="mx-auto mb-10 rounded-lg my-5 bg-[#F6F6F6] px-6 py-6 w-[60%] gap-4 font-extrabold text-sm grid grid-cols-2">
                                    <div className="col-span-1">
                                        <div className="flex gap-2">
                                            <div className="text-[#807D7E]">Shipping Address</div>
                                        </div>
                                        <div className="flex gap-2">
                                            <div className="text-[#807D7E]">City</div>
                                        </div>
                                        <div className="flex gap-2">
                                            <div className="text-[#807D7E]">Phone Number</div>
                                        </div>
                                        <div className="flex gap-2">
                                            <div className="text-[#807D7E]">Postal Code</div>
                                        </div>
                                        <div className="flex gap-2">
                                            <div className="text-[#807D7E]">Country</div>
                                        </div>
                                        <div className="flex gap-2">
                                            <div className="text-[#807D7E]">User Name</div>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="text-black">
                                            {orderDetails?.shippingInfo?.address}
                                        </div>
                                        <div className="text-black">
                                            {orderDetails?.shippingInfo?.city}
                                        </div>
                                        <div className="text-black">
                                            {orderDetails?.shippingInfo?.phoneNo}
                                        </div>
                                        <div className="text-black">
                                            {orderDetails?.shippingInfo?.postalCode}
                                        </div>
                                        <div className="text-black">
                                            {orderDetails?.shippingInfo?.country}
                                        </div>
                                        <div className="text-black">
                                            {orderDetails?.user?.firstName}{" "}
                                            {orderDetails?.user?.lastName}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col gap-3 mx-auto bg-[#F6F6F6] rounded-lg px-6 py-2 w-[80%]">
                                    {orderDetails?.secondaryOrders?.map(
                                        (secondaryOrder, idx) => (
                                            <div key={secondaryOrder?._id}>
                                                <ProductCard
                                                    data={secondaryOrder?.orderItem}
                                                    total={secondaryOrder?.totalPrice}
                                                    returnOrderHandler={() => returnOrderHandler(secondaryOrder?._id)}
                                                    id={secondaryOrder?._id}
                                                />
                                                {idx !== orderDetails?.secondaryOrders?.length - 1 && (
                                                    <div className="border-b-[1px] my-4 w-[100%] border-[#BEBCBD] mx-auto"></div>
                                                )}
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Layout>
    );
};

export default OrderDetails;
