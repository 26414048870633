import React from 'react'
import Layout from '../../shared/Layout'
import Table from './design/Table'

const Orders = () => {
  return (
    <Layout current="Order">
      <div className='bg-white p-8 h-full'>
        <div className='text-3xl font-semibold'>Orders</div>
        <Table />
      </div>
    </Layout>
  )
}

export default Orders