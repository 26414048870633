/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useAuth } from '../../context/AuthProvider';
import { useNavigate } from 'react-router-dom';

const AuthLayout = ({ children }) => {
    const { auth } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!auth.status) {
            navigate("/")
        } else {
            navigate("/dashboard")
        }
    }, [auth])

    return (
        <div>{children}</div>
    )
}

export default AuthLayout