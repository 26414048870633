import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts';
import API from '../../utils/base.service';


const LineGraph = () => {
    const [series, setSeries] = useState([{
        name: 'Orders',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    }]);

    const [chartOptions, setChartOptions] = useState({
        chart: {
            height: 350,
            zoom: {
                enabled: false,
            },
        },
        colors: ['#FF5733'],
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: true,
            showForSingleSeries: false,
            horizontalAlign: 'center',
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        }
    });

    useEffect(() => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const filteredMonths = months.slice(0, currentMonth + 1);

        setChartOptions(prevOptions => ({
            ...prevOptions,
            xaxis: {
                categories: filteredMonths,
            },
        }));
    }, []);

    useEffect(() => {
        API.get("/admin/orders-by-month")
            .then((res) => setSeries([{ name: 'Orders', data: res?.data }]))
            .catch((err) => console.log(err))
    }, [])

    return (
        <Chart
            options={chartOptions}
            series={series}
            type="line"
            height={300}
        />
    );
};

export default LineGraph;