import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";

const Header = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth()

  const signoutHandler = () => {
    localStorage.removeItem("pure-vibe-admin-token");
    setAuth({
      status: false,
      user: null
    })
    navigate("/");
  }

  return (
    <div className="flex justify-end px-6 py-4">
      <div className="flex gap-4 items-center">
        {/* <div className="relative">
          <img className="h-12 w-auto" src="/img/photo.svg" alt="" />
          <img
            className="h-4 w-auto absolute top-8 right-0"
            src="/img/notif.svg"
            alt=""
          />
        </div> */}

        <div className="flex flex-col items-left">
          <div className="font-medium">{auth?.user?.firstName} {auth?.user?.lastName}</div>
          <div className="text-[red] cursor-pointer" onClick={signoutHandler}>Sign Out</div>
        </div>
      </div>
    </div>
  );
};

export default Header;
