/* eslint-disable react-hooks/exhaustive-deps */
"use Client";

import React, { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext(undefined);

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState({
    status: false,
    user: null
  });

  useEffect(() => {
    if (localStorage.getItem("pure-vibe-admin-token")) {
      setAuth({ ...auth, status: true });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (context === "undefined") {
    throw new Error("Auth must be used within a Auth Provider");
  }

  return context;
}
